<template>
  <div class="home-container">
    <div>

      <div style="    float: right;
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;">
        <div style="display: flex;align-items: center;background: #426AF2;width: 75%;padding: 0.1rem 0.3rem;border-radius: 1rem;justify-content: space-between;">
          <van-icon name="question" color='#ffffff' size="0.6rem" />
          <span style="font-size: 0.3rem;color: #FFFFFF;">规则</span>
        </div>
        <div style="       background: rgb(66, 106, 242);
    font-size: 0.3rem;
    padding: 0.35rem 0.2rem;
    border-radius: 1rem;
    width: 0.8rem;
    opacity: 0.8;
    margin-top: 0.5rem;
    text-align: center;color:#FFFFFF  ; position: relative;    left: 0.8rem;"><span>分享</span>
        </div>
        <div @click="showPopup(2)" style="       background: rgb(66, 106, 242);
    font-size: 0.3rem;
    padding: 0.2rem 0.2rem;
    border-radius: 1rem;
    width: 0.8rem;
    opacity: 0.8;
    margin-top: 0.5rem;
    text-align: center;color:#FFFFFF; position: relative;    left: 0.8rem;"><span>分享统计</span>
        </div>
      </div>
      <img style="    display: block;
    margin: 0 auto;width: 92%;padding-top:12rem" src="../../assets/img2/index/hdlc.png" />
    </div>
    <div>
      <img style="    display: block;
        margin: 0 auto;width: 92%;padding-top:0.5rem" src="../../assets/img2/index/jiaonao.png" />
    </div>
    <div>
      <img style="    display: block;
        margin: 0 auto;width: 92%;padding-top:0.5rem" src="../../assets/img2/index/list.png" />
    </div>
    <div @click="showPopup(1)" style="    position: fixed;
    bottom: 0;    left: 0;
    right: 0;">
      <img style="    display: block;
        margin: 0 auto;width: 92%;padding-top:0.5rem" src="../../assets/img2/index/ljcy.png" />
    </div>
    <van-popup v-model="show">
      <div class='alert_register' style="position: relative;">
        <div style="position: absolute;width: 100%;    height: 1rem;" @click="closealert"></div>
        <div v-show="shows == '1'" style="    width: 90%;
    margin: 0 auto;
    padding-top: 3.5rem;">
          <input id="target" v-model="phone" placeholder="请输入手机号" style="    width: 100%;
    background: #F5F5F5;
    font-size: 0.4rem;
    color: #3C91FF;
    border-radius: 1rem;
    padding: 0.2rem;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;" />
          <div>
            <img @click='submits' style="    display: block;
        margin: 0 auto;width: 70%;padding-top:1.5rem" src="../../assets/img2/cyhd.png" />
          </div>
        </div>
        <div v-show="shows == '2'">
          <table border="1" cellspacing='0' style="
    font-size: 0.35rem;
    width: 90%;
    position: relative;
    margin: 0 auto;
    text-align: center;
    border: 2px solid #3D68F8;
    top: 3rem;">

            <tr style="color: #3D68F8;">
              <th>月份</th>
              <th>新用户</th>
              <th>老用户</th>
              <th>下载量</th>
              <th>转存量</th>
            </tr>
            <tr>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
            </tr>
            <tr>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
              <td>2021.12</td>
            </tr>
          </table>

        </div>
      </div>

    </van-popup>
  </div>
</template>

<script>
  import {
    getReceiveCoupon,
    getCouponInfo
  } from '@/api/user'
  import {
    myfun
  } from '@/assets/hcy/product/sensorsdata.full.202111010900.js'
  import {
    wxApi
  } from '@/api/wxapi.js'

  import {
    mapState
  } from 'vuex'
  import {
    getItem,
    setItem
  } from '@/utils/storage'

  export default {
    name: 'home',

    props: {},
    data() {
      return {
        shows: '1',
        phone:'',
        active: 0, //被激活的列表
        channels: [], //频道列表
        isChannelEditShow: false, //频道编辑层
        navlist: [{
            name: '未使用'
          },
          {
            name: '已使用'
          },
          {
            name: '已失效'
          }

        ],
        list: [],
        loading: false,
        detailid: '',
        data: [],
        show: false,
        finished: false,
        refreshing: false,
      }
    },

    watch: {},
    created() {
      // this.loadChannels()
    },
    mounted() {
      // wxapi.wxRegister(this.wxRegCallback)
    },
    methods: {

      submits(){
        var init = {
          sdk_url: '/assets/hcy/product/sensorsdata.full.202111010900.js', // sdk源码
          heatmap_url: '../../product/heatmap.full.js', // 点击图源码
          name: 'sensors',
          server_url: 'https://datacenter.mail.10086.cn/datacenter/', // 服务器接收地址
          show_log: true, // 日志打印
          send_type: 'ajax',
          heatmap: {
            // 开启页面点击事件($WebClick)
            clickmap: 'default',
            //开启视区停留事件($WebStay)
            scroll_notice_map: 'default'
          },
        }
         var ssq = myfun(init)
ssq.putPhoneNumberInfo(this.phone)

 document.getElementById("target").click()
      },
      closealert() {
        this.show = false
      },
      wxRegCallback() {
        // 用于微信JS-SDK回调
        this.wxShareTimeline()
        this.wxShareAppMessage()
      },
      wxShareTimeline() {
        // 微信自定义分享到朋友圈
        let option = {
          title: '限时团购周 挑战最低价', // 分享标题, 请自行替换
          link: window.location.href.split('#')[0], // 分享链接，根据自身项目决定是否需要split
          imgUrl: 'logo.png', // 分享图标, 请自行替换，需要绝对路径
          success: () => {
            alert('分享成功')
          },
          error: () => {
            alert('已取消分享')
          }
        }
        // 将配置注入通用方法
        wxapi.ShareTimeline(option)
      },
      wxShareAppMessage() {
        // 微信自定义分享给朋友
        let option = {
          title: '限时团购周 挑战最低价', // 分享标题, 请自行替换
          desc: '限时团购周 挑战最低价', // 分享描述, 请自行替换
          link: window.location.href.split('#')[0], // 分享链接，根据自身项目决定是否需要split
          imgUrl: 'logo.png', // 分享图标, 请自行替换，需要绝对路径
          success: () => {
            alert('分享成功')
          },
          error: () => {
            alert('已取消分享')
          }
        }
        // 将配置注入通用方法
        wxapi.ShareAppMessage(option)
      },
      showPopup(shows) {
        this.shows = shows
        var init = {
          sdk_url: '/assets/hcy/product/sensorsdata.full.202111010900.js', // sdk源码
          heatmap_url: '../../product/heatmap.full.js', // 点击图源码
          name: 'sensors',
          server_url: 'https://datacenter.mail.10086.cn/datacenter/', // 服务器接收地址
          show_log: true, // 日志打印
          send_type: 'ajax',
          heatmap: {
            // 开启页面点击事件($WebClick)
            clickmap: 'default',
            //开启视区停留事件($WebStay)
            scroll_notice_map: 'default'
          },
        }
        var ssq = myfun(init)
        // var msq1 = ssq.init(init)
        ssq.initPara(init)
        var msq1 = ssq.init(init)
        ssq.registerPage({
          platForm: 'activity_marketing',
          activityName: 'shandong_seed_share_activity'
        });
        var mes = ssq.registerPage({
          platForm: 'activity_marketing',
          activityName: 'shandong_seed_share_activity'
        });
        var mes = ssq.putPhoneNumberInfo('13777777779')
        console.log(ssq)
        console.log(mes)
        this.show = true
      },
      async onLoad() {
        let params = {
          storeCouponTypeId: this.detailid
        }
        console.log(params)
        const {
          data
        } = await getCouponInfo(params)
        this.data = data.data

      },
      async clickdetial() {
        let params = {
          "userId": 12,
          "storeCouponTypeId": this.detailid,
        }
        const {
          data
        } = await getReceiveCoupon(params)
      }


      // onUpdateActive(index) {
      //   this.active = index
      // }
    }
  }
</script>

<style lang="less">
  html,
  body,
  #app {
    width: 100%;
    // height: 100%;/
  }

  th,
  td {
    padding: 0.25rem 0.13rem;
    box-sizing: border-box;
    border: 1px solid #8B64F4;
  }

  .home-container {
    width: 100%;
    background-image: url(../../assets/img2/index/banner.png);
    height: 34.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;

    .alert_register {


      background-image: url(../../assets/img2/alertback.png);

      height: 8rem;
      width: 8.5rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: 0;
    }

    .van-popup {
      background: none;
    }
  }
</style>
